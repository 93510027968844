<template>
    <div class="row h-100">
        <div class="col-12">
            <div class="course-card">
                <div class="card-images text-center">
                    <div class="image-container">
                        <div class="image-box shadow-md bg-transparent cursor-pointer" @click="toCourse(courses[index].slug)">
                            <img class="rounded-lg" width="100%" :src=" courses[index].banner" alt="">
                        </div>
                    </div>
                </div>
                <div class="card-body shadow-lg">
                    <div class="row">
                        <div class="col-12 text-center mb-4 nav-container">
                            <a href="javascript:void(0)" v-if="this.courses.length > 1" @click="prev()" class="btn nav-button py-1 pl-3 pr-4 text-center mx-3"><i class="display2-size fas fa-caret-left"></i></a>
                            <a href="javascript:void(0)" v-if="this.courses.length > 1" @click="next()" class="btn nav-button py-1 pl-4 pr-3 text-center mx-3"><i class="display2-size fas fa-caret-right"></i></a>
                        </div>
                        <div class="col-12 text-center mb-4">
                            <router-link :to="{name: 'CourseDetails', params: { idCourse: courses[index].slug }}" class="fw-600 font-xl text-black">{{ courses[index].name }}</router-link>
                        </div>
                        <div class="col-12 text-center">
                            <h3 class="fw-500 font-md"><i class="fas fa-wifi mr-2"></i>{{ courses[index].category }}-{{ courses[index].level }}</h3>
                            <h4 class="fw-500 font-md" v-if="courses[index].point_status == 1 && courses[index].point_type == 'AMOUNT'"><i class="fas fa-certificate mr-2"></i>Cashback {{ courses[index].point_amount | numFormat('0,0') }}</h4>
                            <h4 class="fw-500 font-md" v-else-if="courses[index].point_status == 1 && courses[index].point_type == 'PERCENT'"><i class="fas fa-certificate mr-2"></i>Cashback {{ courses[index].point_amount }}%</h4>
                            <h4 class="fw-500 font-md" v-else>&nbsp;</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['courses'],
    data() {
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            index: 0,
        }
    },
    methods: {
        toCourse(slug) {
            this.$router.push({ name: 'CourseDetails', params: { idCourse: slug }})
        },
        prev() {
            let length = this.courses.length
            if(this.index == 0) {
                this.index = length-1;
            } else {
                this.index -= 1;
            }
        },
        next() {
            let length = this.courses.length
            if(this.index == (length - 1)) {
                this.index = 0;
            } else {
                this.index += 1;
            }
        },
    },
}
</script>
<style scoped>
.section-four {
    background-image: url('/images/bg/sec4-bottom-bg.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
}
.course-card .card-images {
    position: relative;
    width: 100%;
    top: 130px;
    padding: 0px 20px;
}
.course-card .card-images .image-box {
    padding: 20px;
    border-radius: 20px;
    border: 0px solid gray;
    z-index: 9999;
}
.course-card .card-images .image-container {
    height: fit-content;
    width: fit-content;
    background-color: transparent;
    border-radius: 20px;
    padding: 20px 10px;
}
.course-card .card-body {
    padding-top: 150px;
    padding-bottom: 50px;
    border-radius: 15px;
    background-color: #fff;
    min-height: 70vh;
}
.course-card .card-body:hover {
    background-color: #FE9431;
}
.nav-button {
    border-radius: 10px;
    background-color: #eee;
}
.nav-button i {
    color: #000;
}
.nav-container {
    min-height: 8vh;
}
</style>