<template>
    <div class="section-four w-100 py-5">
        <div class="row justify-content-center py-lg-4 mb-lg-5 mb-3">
            <div class="col-10">
                <h2 class="fw-600 display2-size mb-3">English Solutions:</h2>
                <div class="row">
                    <div class="col-lg-7">
                        <p class="fw-500 font-sm mb-3 text-black">
                            Upskill, reskill or pursue a passion with short courses across every subject, whether you're a beginner or already an expert 
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mb-0">
            <div class="col-lg-10 col-11 text-center">
                <h3 class="fw-500 font-xxl">Get <span class="text-caveat">WIN</span> learning, you decide you have it</h3>
                <h3 class="fw-500 font-xxl mb-3">Wants - Interests - Needs</h3>
            </div>
        </div>
        <div class="row justify-content-center min-vh-50">
            <div class="col-10">
                <div class="row justify-content-center" v-if="!isLoad">
                    <div class="col-lg-3 col-md-6" v-if="data.shine.length">
                        <CourseCard :courses="data.shine"/>
                    </div>
                    <div class="col-lg-3 col-md-6" v-if="data.glory.length">
                        <CourseCard :courses="data.glory"/>
                    </div>
                    <div class="col-lg-3 col-md-6" v-if="data.triumph.length">
                        <CourseCard :courses="data.triumph"/>
                    </div>
                    <div class="col-lg-3 col-md-6" v-if="data.exclusive.length">
                        <CourseCard :courses="data.exclusive"/>
                    </div>
                </div>
                <div class="row pt-lg-3 pt-1" v-else>
                    <div class="col-12 text-center">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mt-5 pt-lg--10">
            <div class="col-8">
                <div class="testimony-box">
                    <div class="row">
                        <div class="col-lg-6">
                            <video width="100%" controls controlsList="nodownload" :poster="'/images/thumbnail-new.png'">
                                <source :src=" '/storage/default/Elingway-Explainer.mp4'" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div class="col-lg-6">
                            <div class="row" v-if="!isLoadTestimony">
                                <div class="col-12 py-3 pl-lg-5" v-if="testimony.id">
                                    <h2 class="fw-600 font-xxl mb-3 text-caveat">{{ testimony.name }}</h2>
                                    <p class="fw-600 font-sm text-black"><i class="fas fa-certificate"></i> {{ testimony.job }}</p>
                                    <p class="fw-400 font-sm mb-3 text-black">{{ testimony.text }}</p>
                                </div>
                                <div class="col-12 py-3 pl-lg-5" v-else>
                                    <img :src="'/images/empty.png'" alt="" width="300">
                                    <h1 class="text-muted">Data don't exist</h1>
                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="col-12">
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CourseCard from './CourseCard.vue'
import axios from 'axios'
export default {
    components: {
        CourseCard,
    },
    data() {
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            isLoadTestimony: true,
            data: {
                shine: [],
                glory: [],
                triumph: [],
                exclusive: [],
            },
            testimony : {
                name: '',
                email: '',
                phone: '',
                job: '',
                address: '',
                text: '',
            }
        }
    },
    created() {
        this.getCourseByCategory()
        this.getTestimony()
    },
    methods: {
        async getCourseByCategory() {
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/course/per-type`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.data = res.data.data
                this.isLoad = false
            })
        },
        async getTestimony(){
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/testimony`, {
                headers: {
                Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.testimony = res.data.data
                }
            }).catch((err) => console.error(err.response)).finally(() => this.isLoadTestimony = false)
        },
    } 
}
</script>
<style scoped>
.section-four {
    background-image: url('/images/bg/sec4-bottom-bg.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
}
.course-card .card-image {
    position: relative;
    width: 100%;
    top: 130px;
    padding: 0px 20px;
}
.course-card .card-image .image-box {
    padding: 20px;
    border-radius: 20px;
    border: 0px solid gray;
    z-index: 9999;
}
.course-card .card-image .image-container {
    height: fit-content;
    width: fit-content;
    background-color: transparent;
    border-radius: 20px;
    padding: 20px 10px;
}
.course-card .card-body {
    padding-top: 150px;
    padding-bottom: 50px;
    border-radius: 15px;
    background-color: #fff;
}
.course-card .card-body:hover {
    background-color: #FE9431;
}
.nav-button {
    border-radius: 10px;
    background-color: #eee;
}
.nav-button i {
    color: #000;
}
</style>