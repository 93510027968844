<template>
    <div class="section-partner w-100 py-5">
        <div class="row justify-content-center py-lg-4 mb-lg-5 mb-3">
            <div class="col-10">
                <h1 class="fw-600 title-partner text-warning mb-3">We work with awesome partners</h1>
            </div>
            <div class="col-12 rotate-content mt-5">
                <div class="line-partner"></div>
                <div class="line-partner mt-5"></div>
            </div>
            <div class="col-12 d-flex align-items-center my-5 px-5 image-content">
                <div v-for="(item, index) in partners" :key="index">
                    <img class="image-partner" :src=" item.image"/>
                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="line-partner"></div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            partners : []
        }
    },
    created() {
        this.getPartner()
    },  
    methods: {
        async getPartner(){
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/partner`, {
                headers: {
                Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.partners = res.data.data
                }
            }).catch((err) => console.error(err.response)).finally(() => this.isLoad = false)
        },
    }
}
</script>
<style scoped>
.section-partner {
}

.title-partner {
    font-size: 44px;
}

.line-partner {
    border-top: 1px solid #FE9431;
}
.rotate-content {
    transform: rotate(3deg);
}

.image-content {
    transform: rotate(3deg);
    animation: scroll 10s linear infinite;
}
.image-content > div:not(:first-child) {
    margin-left: 4rem;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-150px * 7));
    }
}
.image-partner {
    height: 100px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: .5s
}


.image-partner:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}
@media (max-width: 991px) { 
    .title-partner {
        font-size: 30px;
    }
    .image-partner {
        height: 70px;
    }
}

</style>