<template>
    <div class="row m-0 p-0">
        <div class="col-12 p-0 m-0">
            <SectionOne :freeCourse="free_courses"></SectionOne>
            <SectionTwo :freeCourse="free_courses"></SectionTwo>
            <SectionPartner></SectionPartner>
            <SectionTOEFL></SectionTOEFL>
            <SectionThree></SectionThree>
            <SectionFour></SectionFour>
            <!-- <SectionFive></SectionFive> -->
        </div>
    </div>
</template>
<script>
import SectionOne from './Section/SectionOne.vue'
import SectionTwo from './Section/SectionTwo.vue'
import SectionThree from './Section/SectionThree.vue'
import SectionFour from './Section/SectionFour.vue'
import SectionPartner from './Section/SectionPartner.vue'
import SectionTOEFL from './Section/SectionTOEFL.vue'
// import SectionFive from './Section/SectionFive.vue'
import axios from 'axios'
export default {
    components: {
        SectionOne,
        SectionTwo,
        SectionThree,
        SectionFour,
        SectionPartner,
        SectionTOEFL,
        // SectionFive,
    },
    data() {
        return {
            free_courses: null
        }
    },
    created() {
        this.getFreeCourses()
    },
    methods: {
        async getFreeCourses(){
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/course/free-trial`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.free_courses = res.data.data
                }
            }).catch((err) => console.error(err))
        },
    }
}
</script>