<template>
    <div class="section-toefl w-100 py-3 py-lg-5">
        <div class="row justify-content-center">
            <div class="col-lg-9 col-10">

                <div class="row">
                    <div class="col-lg-4 text-center">
                        <vue-slick-carousel v-bind="slickOptions" class="carousel-container">
                            <div v-for="(image, index) in carouselImages" :key="index" class="carousel-item">
                                <img :src="image.src" class="toefl-image" alt="">
                            </div>
                        </vue-slick-carousel>
                    </div>
                    <div class="col-lg-8 align-item-center">
                        <div class="row">
                            <div class="col-12">
                                <p class="fw-700 display1-size text-toefl-red mb-2">TOEFL ITP - TOEIC - TOEFL iBT - IELTS
                                </p>
                            </div>
                            <div class="col-12">
                                <p class="fw-600 font-xs text-toefl">
                                    Elingway Adalah Platform Pembelajaran Bahasa Asing Yang Telah Bekerjasama Sebagai
                                    Penyedia Jasa TOEFL ITP, TOEIC, TOEFL iBT, IELTS.
                                </p>
                            </div>
                            <div class="col-12">
                                <router-link to="/toefl" class="btn register-button">Register</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            slickOptions: {
                dots: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                speed: 1000,
                autoplaySpeed: 3000,
                cssEase: "linear",
                swipe: true
            },
            carouselImages: [
                { id: 1, src: '/images/program/toeflitp.webp' },
                { id: 2, src: '/images/program/toeflibt.webp' },
                { id: 3, src: '/images/program/toeic.webp' },
                { id: 4, src: '/images/program/ielts.webp' },
            ],
            laptopOver: false,
            calendarOver: false,
            presentationOver: false,
            messageOver: false,
        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    .head-text {
        text-align: center;
    }

    .display1-size {
        font-size: 25px !important;
        text-align: center;
    }

    .font-xs {
        font-size: 20px !important;
        text-align: center;
    }

    .toefl-image {
        width: 300px;
    }
}

.section-toefl {
    border-top: 10px solid #6B080D;
    border-bottom: 10px solid #213E7A;
}

.text-toefl-red {
    color: #6B080D;
}

.text-toefl {
    color: #213E7A;
}

.register-button {
    padding: 10px 30px;
    border: 5px solid #213E7A;
    font-weight: 700;
    font-size: 18px;
    color: #213E7A;
}

.register-button:hover {
    background: #213E7A;
    color: #fff;
}

.carousel-container {
    max-width: 100%;
}

.carousel-item {
    text-align: center;
}

.toefl-image {
    width: 100%;
}
</style>
