<template>
    <div class="section-one w-100 pt-10">
        <div class="row justify-content-center min-vh-150">
            <div class="col-10">
                <div class="row justify-content-center mb-lg-5 mb-3 pt-3">
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-12 mb-4">
                                <h1 class="fw-500 text-white display1-size ">Find the way to solve<br>English Problems <img width="180px" :src="'/images/bg/sec1-differently-text.png'" alt=""></h1>
                            </div>
                            <form @submit.prevent="searchBlog()" class="col-lg-10">
                                <input v-model="search" type="text" class="form-control fw-500" placeholder="What do you want to learn today?">
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-12 maskot-container text-right">
                                <img width="95%" :src="'/images/bg/sec1-maskot.png'" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-lg-5">
                    <div class="col-12 text-center pt-lg-5">
                        <router-link v-if="freeCourse" :to="{name: 'CourseDetails', params: { idCourse: freeCourse.slug }}" class="btn start-trial-button">START YOUR RISK FREE-TRIAL<i class="fas fa-arrow-right ml-2"></i></router-link>
                        <router-link v-else :to="'/login'" class="btn start-trial-button">START YOUR RISK FREE-TRIAL<i class="fas fa-arrow-right ml-2"></i></router-link>
                    </div>
                </div>
                <div class="bottom-area pb-lg-5 pb-3">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-9">
                            <div class="row">
                                <div class="col-12">
                                    <h1 class="fw-500 text-white1 display1-size pt-5 mb-lg-5 mb-3 topics-text">Find topics you are<br>interested in</h1>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-auto mb-lg-4 mb-2 p-1" v-for="(item, index) in tagline" :key="index">
                                            <a href="javascript:void(0)" @click="searchBlogFromTagline(item)" class="topics-button">{{ item }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 text-right pt-5">
                            <img width="100%" class="bottom-image" :src="'/images/bg/sec1-bottom-image.svg'" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['freeCourse'],
    data() {
        return {
            search: '',
            tagline: [
                'Listening',
                'Vocabulary Success',
                'Popular',
                'Vocabulary Building',
                'English Writing',
                'English Idioms',
                'Profesional Settings',
                'TOEFL',
                'IELTS'
            ]
        }
    },
    methods: {
        searchBlog() {
            this.$router.push({ name: 'BlogListBrowseNew', query: { search: this.search } })
        },
        searchBlogFromTagline(tag) {
            this.$router.push({ name: 'BlogListBrowseNew', query: { search: tag } })
        },
    }
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    .section-one {
        min-height: 100vh !important;
        background-size: 115% !important;
    }
    .start-trial-button {
        font-size: 90% !important;
        padding: 5px 15px !important;
    }
    .start-trial-button i {
        display: none !important;
    }
    .bottom-area {
        position: inherit !important;
    }
    .topics-button {
        padding: 5px 10px !important;
        font-size: 14px !important;
        color: #ffffff !important;
        border-color: #ffffff !important;
    }
    .topics-text {
        font-size: 22px !important;
        text-align: center;
    }
    .maskot-container {
        text-align: center !important;
        position: inherit !important;
    }
    .maskot-container img {
        position: inherit !important;
        width: 40%;
    }
    .bottom-image {
        width: 30%;
        padding-top: 100px;
    }
}
.maskot-container {
    position: relative;
}
.maskot-container img {
    position: absolute;
    left: 20%;
}
.section-one {
    background-image: url('/images/bg/sec1-bottom-background-new.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    min-height: 150vh;
    -webkit-animation: bgcolorchange 10s infinite; /* Chrome, Safari, Opera */ 
    animation: 10s infinite bgcolorchange;
}   
.start-trial-button {
    width: fit-content;
    padding: 10px 20px;
    border: 4px solid #FFF;
    color: #FFF;
    font-size: 20px;
    text-overflow:unset;
}

.topics-text {
    color: #ffffff !important;
}
.bottom-area {
    position: absolute;
    bottom: 0;
}
.topics-button {
    padding: 10px 15px;
    border: 3px solid #fff;
    color: #fff;
    font-size: 18px;
    border-radius: 25px;
}
.topics-button:hover {
    border: 3px solid #fff;
    background-color: #fff;
    color: #000;
}
@keyframes bgcolorchange {
  0% {
    background-color: #B22222;
  }
  25% {
    background-color: #CB7627;
  }
  50% {
    background-color: #964B00;
  }
  75% {
    background-color: #19674C;
  }
  100% {
    background-color: #B22222;
  }
}
/* Chrome, Safari, Opera */
 @-webkit-keyframes bgcolorchange {
    0% {
    background-color: #B22222;
    }
    25% {
        background-color: #CB7627;
    }
    50% {
        background-color: #964B00;
    }
    75% {
        background-color: #19674C;
    }
    100% {
        background-color: #B22222;
    }
 }
 
</style>