<template>
    <div class="section-three w-100 py-3 py-lg-5">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-10 head-text">
                <p class="fw-400 font-md text-black mb-1">Excellent educational resource</p>
                <p class="fw-400 font-xl text-black">More efficiently assisting the learning process</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-9 col-10">
                <div class="card-box">
                    <img class="bottom-left" :src="'/images/bg/sec2-bottom-left-border.svg'" width="80px" alt="">
                    <img class="top-right" :src="'/images/bg/sec2-top-right-border.svg'" width="80px" alt="">
                    <div class="row">
                        <div class="col-lg-3 mb-3">
                            <div class="card-profit">
                                <div class="main-layer" @mouseover="laptopOver = true" @mouseleave="laptopOver = false">
                                    <img height="50px" :src="!laptopOver ? '/images/bg/sec2-laptop-logo.png' : '/images/bg/sec2-laptop-white-logo.png'" alt=""><br>
                                    <span class="font-xsss fw-600">Interactive platform<br>learning</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="card-profit">
                                <div class="main-layer" @mouseover="calendarOver = true" @mouseleave="calendarOver = false">
                                    <img height="50px" :src="!calendarOver ? '/images/bg/sec2-calendar-logo.png' : '/images/bg/sec2-calendar-white-logo.png'" alt=""><br>
                                    <span class="font-xsss fw-600">Class schedule<br>flexibility</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="card-profit">
                                <div class="main-layer" @mouseover="presentationOver = true" @mouseleave="presentationOver = false">
                                    <img height="50px" :src="!presentationOver ? '/images/bg/sec2-presentation-logo.png' : '/images/bg/sec2-presentation-white-logo.png'" alt=""><br>
                                    <span class="font-xsss fw-600">Monthly full<br>report</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="card-profit">
                                <div class="main-layer" @mouseover="messageOver = true" @mouseleave="messageOver = false">
                                    <img height="50px" :src="!messageOver ? '/images/bg/sec2-message-logo.png' : '/images/bg/sec2-message-white-logo.png'" alt=""><br>
                                    <span class="font-xsss fw-600">Consult with<br>experties</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            laptopOver: false,
            calendarOver: false,
            presentationOver: false,
            messageOver: false,
        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    .head-text {
        text-align: center;
    }
}
@media only screen and (max-width: 515px) {
    .card-box {
        padding: 30px 35px !important;
    }
    .card-box .bottom-left {
        left: 5% !important;
        width: 50px;
    }
    .card-box .top-right {
        right: 5% !important;
        width: 50px;
    }
}
.section-three {
    background: rgb(34,193,195);
    background: radial-gradient(circle, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
}
.card-box {
    padding: 65px;
}
.card-box .bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
}
.card-box .top-right {
    position: absolute;
    right: 0;
    top: 0;
}
.card-profit .main-layer {
    background-color: #fff;
    border-radius: 15px;
    z-index: 999;
    padding: 15px;
    color: #000;
}
.card-profit .hover-layer {
    background-color: #FE9431;
    border-radius: 15px;
    padding: 15px;
}
.card-profit .main-layer:hover {
    text-align: center;
    background-color: #FE9431;
    color: #fff;
    
}
</style>